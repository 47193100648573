
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($, _c, undefined) {
	'use strict';

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				$('.hamburger').on('click', function() {
					$(this).toggleClass('is-active');
					if ($(this).hasClass('is-active')) {
						$('.topnav').slideDown(500);
					} else {
						$('.topnav').slideUp(500);
					}
				});
				$(window).resize(function(){
					$('.topnav').attr('style', '');
					$('.hamburger').removeClass('is-active');
				});

				$('#menu-top-nav li:last-of-type a').attr('target', '_blank');

				// Scroll To
				(function() {
					$('.scrollto').on('click', function(e){
						e.preventDefault();
						var section = $(this).attr('href');
						$('html, body').animate({
				            scrollTop: $(section).offset().top
				        }, 1000);
					});
				})();

				$('#hero img').click(function(){
					var nextSection = $('#hero').next('section');
					$('html, body').animate({
						scrollTop: $(nextSection).offset().top
					}, 1000);
				});

				// Form
				(function() {
					$('select').selectric();
				})();


                $('input[type=checkbox]').change(function(){
                    if (this.checked) {
                        $('.detail-sidebar .level-1').hide('fast');
                    } else {
                        $('.detail-sidebar .level-1').show('fast');
                    }
                });

                $('.drawer ul li a').click(function(e){
                    e.preventDefault();
                    $(this).parent().toggleClass('open-level');
                    $(this).next().toggle('fast');
                });

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},

		// Home page
		'page_template_template_home': {
			init: function() {
				// $(document).ready(function(){
				// 	setTimeout(function(){
				// 		$('.hero-content p').addClass('animated fadeIn');
				// 	}, 250);
				// 	setTimeout(function(){
				// 		$('.hero-content img').addClass('animated fadeIn');
				// 	}, 500);
				// });

				// var waypoint1 = new Waypoint({
				// 	element: document.querySelector('.three-column'),
				// 	handler: function(direction) {
				// 		if (direction === 'down'){
				// 			$('.three-column-container').addClass('animated fadeIn');
				// 		}
				// 	},
				// 	offset: '65%'
				// });

				// var waypoint2 = new Waypoint({
				// 	element: document.querySelector('.why-develop'),
				// 	handler: function(direction) {
				// 		if (direction === 'down'){
				// 			$('.why-develop-content').addClass('animated fadeIn');
				// 		}
				// 	},
				// 	offset: '65%'
				// });
				// var waypoint3 = new Waypoint({
				// 	element: document.querySelector('.two-columns'),
				// 	handler: function(direction) {
				// 		if (direction === 'down'){
				// 			$('.two-column-container').addClass('animated fadeIn');
				// 		}
				// 	},
				// 	offset: '65%'
				// });

				// var waypoint4 = new Waypoint({
				// 	element: document.querySelector('.aprimo-academy'),
				// 	handler: function(direction) {
				// 		$('.aprimo-academy-content').addClass('animated fadeIn');
				// 		$('.aprimo-academy .one-third-cards').addClass('animated fadeIn');
				// 	},
				// 	offset: '65%'
				// });

			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'page_template_template_faq': {
			init: function() {
				$('li h3').click(function(e){
					$(this).parent().parent().toggleClass('open-li');
					$(this).parent().find('p').toggle('fast');
				});
				$('li img').click(function(e){
					$(this).parent().toggleClass('open-li');
					$(this).parent().find('p').toggle('fast');
				});

                $.expr[":"].contains = $.expr.createPseudo(function(arg) {
                    return function( elem ) {
                        return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
                    };
                });

                $('input[name=search]').keyup(event, function(){
                    var value = $(this).val();

                    $('.faqs-list > li .faq-content:not(:contains(' + value + '))').parent().hide();
                    $('.faqs-list > li .faq-content:contains(' + value + ')').parent().show();
                });
			},	
		},

		'page_template_template_detail': {
			init: function() {

				var cat = $('.detail-sidebar-header').text();
				$('.detail-sidebar form input[name=query]').attr('placeholder', 'Search ' + cat);

				$('input[type=checkbox]').change(function(){
					if (this.checked) {
						$('.detail-sidebar .level-1').hide('fast');
					} else {
						$('.detail-sidebar .level-1').show('fast');
					}
				});

				$('li.has-sublevels').prepend('<span></span>');

				$('.level-1 .has-sublevels span').on('click', function(){
					$(this).parent().toggleClass('open-level');
					$(this).parent().next('.level-2').css('border-bottom', '1px solid #cfcfcf');
					$(this).parent().next('.level-2').toggle('fast'); 
				});
				$('.level-2 .has-sublevels span').on('click', function(){
					$(this).toggleClass('open-level');
					$(this).parent().next('.level-3').toggle('fast'); 
				});

				$('.drawer ul li a').click(function(e){
					e.preventDefault();
					$(this).parent().toggleClass('open-level');
					$(this).next().toggle('fast');
				});

				var loc = window.location.href;
				$('.level-2 li a').each(function(index, item) {
					if ($(item).attr('href') === loc) {

						$(this).parent().parent().prev('li').addClass('open-level');
						$(this).parent().parent().prev('li').next('.level-2').slideDown('fast'); 
						$(this).parent().parent().prev('li').next('.level-2').css('border-bottom', '1px solid #cfcfcf');
					}
				});

				$('.level-3 li a').each(function(index, item) {
					if ($(item).attr('href') === loc) {
						$(this).parent().parent().parent().prev('li').addClass('open-level');
						$(this).parent().parent().parent().prev('li').next('.level-2').css('border-bottom', '1px solid #cfcfcf').slideDown('fast');
						$(this).parent().parent().prev('li').addClass('open-level');
						$(this).parent().parent().prev('li').next('.level-3').slideDown('fast'); 
					}
				});
				

				// var btn = $('.copy-button button');

				$('pre').each(function(i, e){
					$(this).attr('id', 'pre_' + i);
				});

				$('.copy-button button').each(function(i, e){
					var tempTarget = $(this).parent().siblings('.copy-me').children('pre').attr('id');
					$(this).attr('data-clipboard-target', '#' + tempTarget)
				});

				var clipboard = new Clipboard('.copy-button button');
				clipboard.on('success', function(e) {
					var trigger = e.trigger;
					e.clearSelection();
					$(trigger).siblings().addClass('animated-once fadeInOut');
					setTimeout(function(){
						$(trigger).siblings().removeClass();
					}, 2500);
				});

				$('a.button').next().css('margin-top', '50px');

				$(window).on('load resize', function(){
					if (window.matchMedia("(max-width: 768px)").matches) {
						$('.detail-sidebar-header').click(function(e){
							$('.detail-sidebar form').toggleClass('open-mobile-form');
							$(this).toggleClass('open-detail-sidebar-header');
							$(this).siblings().toggle();
							$('.detail-content').toggleClass('open-sidebar');
						});
					}
				});
			},
		},

	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = funcname === undefined ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
